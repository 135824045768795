import * as React from 'react';
import { FlexRow } from '@patterns/ui';
import NavBar from '../components/navbar';

export interface Props {
  title: string
  gradient?: boolean
}

export function MainLayout({ children, title, gradient }: React.PropsWithChildren<Props>) {
  const [ className, setClassName ] = React.useState(gradient ? 'content-full content-full-gradient f-1' : 'content-full f-1');

  React.useEffect(() => {
    if (gradient) {
      setClassName('content-full content-full-gradient f-1')
    }
  }, [gradient])

  return (
    <React.Fragment>
      <NavBar/>
      <FlexRow style={{ minHeight: '100vh'}}>
        <div className={className}>
          { children }
        </div>
      </FlexRow>
    </React.Fragment>
  )  
}
