import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../layouts/main.layout';
import { Tabs, Tab } from '@blueprintjs/core';
import { FlexColumn } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumbs } from '../breadcrumbs';
import UsersView from '../components/settings/users';

import CustomColumns from '../components/settings/custom_columns';
import { session } from '../session';
import Icons from '../components/settings/icons';
import CategoriesView from '../components/settings/categories';

interface Props extends WithTranslation, RouteComponentProps {}

export interface State {
  tabId: string
}

export class Settings extends React.Component<Props, State> {
  state = {
    showDialog: false,
    tabId: 'custom_columns'
  } as State

  private onTabChange = (tabId: string) => this.setState({ tabId })

  public render() {
    const { t } = this.props;

    return (
      <MainLayout
        title="Settings">
        { session.user.isAdmin && this.state.tabId === 'user_accounts' && (
          <Breadcrumbs
            path="Settings / User Accounts"
            title={`${t('settings.title')} / ${t('settings.user_accounts')}`}
          />
        )}
        {/* {this.state.tabId === 'product_groups' && (
          <Breadcrumbs
            path="Settings / Product Groups"
            title={`${t('settings.title')} / ${t('settings.product_groups')}`}
          />
        )} */}
        {this.state.tabId === 'categories' && (
          <Breadcrumbs
            path="Settings / Categories"
            title={`${t('settings.title')} / ${t('settings.categories')}`}
          />
        )}
        {this.state.tabId === 'custom_columns' && (
          <Breadcrumbs
            path="Settings / Custom Columns"
            title={`${t('settings.title')} / ${t('settings.custom_columns')}`}
          />
        )}
        {this.state.tabId === 'icons' && (
          <Breadcrumbs
            path="Settings / Icons"
            title={`${t('settings.title')} / ${t('settings.icons')}`}
          />
        )}
        <FlexColumn flex={1}>
          <Tabs
            className="custom-tabs"
            selectedTabId={this.state.tabId}
            onChange={this.onTabChange}
            renderActiveTabPanelOnly={true}>
            { session.user.isAdmin && <Tab id="user_accounts" title={t('settings.user_accounts')} panel={<UsersView />}/> }
            {/* <Tab id="product_groups" title={t('settings.product_groups')} panel={<ProductGroupsView />}/> */}
            <Tab id="categories" title={t('settings.categories')} panel={<CategoriesView />}/>
            <Tab
              id="custom_columns"
              title={t('settings.custom_columns')}
              panel={<CustomColumns />}
            />
            { session.user.isAdmin && <Tab id="icons" title={t('settings.icons')} panel={<Icons />}/> }

          </Tabs>
        </FlexColumn>
      </MainLayout>
    );
  }
}

export default withTranslation()(Settings)
