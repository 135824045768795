import { BaseModel, Resource } from '@patterns/core';

@Resource('icons')
export class IconModel extends BaseModel {
  fileName: string;
  order: number;

  constructor(data: any) {
    super(data);
    this.fileName = data.fileName;
    this.order = data.order;
  }

  getLabel() {
    return this.fileName;
  }

  getTitle() {
    return this.fileName;
  }
}
