import { NonIdealState, Spinner } from '@blueprintjs/core';
import * as React from 'react';

import {
  Switch,
  Route
} from "react-router-dom";

import { Logout } from './logout';
import Products from './products';
import Settings from './settings';
import Customers from './customers';

export interface Props {
  onLogout: () => void
}

export interface State {
  isLoading: boolean
}

export class Main extends React.Component<Props, State> {
  state = { isLoading: true }

  componentDidMount() {
    this.fetch()
  }

  async fetch() {
    this.setState({ isLoading: true }, async () => {
      this.setState({ isLoading: false })
    })
  }

  public render() {
    if (this.state.isLoading) {
      return <NonIdealState className="loader-main">
        <div className="container">
          <Spinner size={96} className="m-t-24" intent="success"/>
        </div>
      </NonIdealState>
    }

    return (
      <Switch>
        <Route exact path="/" component={Products}/>
        <Route exact path="/settings" component={Settings}/>
        <Route path="/customers" component={Customers}/>
        <Route path="/products" component={Products}/>
        <Route path="/logout" component={Logout}/>
      </Switch>
    );
  }
}

export default Main