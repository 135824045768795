import { BaseModel, Resource } from '@patterns/core';

export type CustomFieldType = 'string' | 'number' | 'boolean' | 'select';

export const CustomFieldTypes = [
  'string',
  'number',
  'boolean',
  'select'
] as CustomFieldType[];

export interface ICustomField {
  label: string;
  type: CustomFieldType;
  options?: string[];
  order: number;
}

@Resource('custom_fields')
export class CustomField extends BaseModel {
  label: string;
  order: number;
  type: CustomFieldType;
  options: string[];

  constructor(data: ICustomField) {
    super(data);
    this.label = data.label;
    this.order = data.order || 0;
    this.type = data.type;
    this.options = data.options || [];
  }

  getLabel() {
    return this.label;
  }

  getTitle() {
    return this.label;
  }
}
