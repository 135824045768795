import * as React from 'react';
import {
  Dialog,
  Classes,
  FormGroup,
  InputGroup,
  Button,
  MenuItem,
  NumericInput,
} from '@blueprintjs/core';
import { ConfirmButton, FlexColumn, FlexRow } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Select } from '@blueprintjs/select';
import {
  CustomField,
  CustomFieldType,
  CustomFieldTypes,
} from '../../models/custom_field.model';

const TypeSelect = Select.ofType<CustomFieldType>();

export interface Props extends WithTranslation {
  isOpen: boolean;
  onClose: () => void;
  onSave: (customField: CustomField) => void;
  onDelete: (customField: CustomField) => void;
  customField: CustomField;
}

export interface State {
  type: CustomFieldType;
  label: string;
  options: string[];
  order: number;
}

export class CustomFieldDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { type, label, options, order } = this.props.customField;
    this.state = { type, label, options, order };
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.customField, prevProps.customField)) {
      const { type, label, options, order } = this.props.customField;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ type, label, options, order });
    }
  }

  private typeRenderer = (type: CustomFieldType, options: any) => {
    const typeString = this.props.t(`custom_fields.${type}`)
    return (
      <MenuItem
        text={typeString}
        key={`menu-item-${type}`}
        onClick={options.handleClick}
      />
    );
  };

  private onTypeSelect = (type: CustomFieldType) => this.setState({ type });

  private onDelete = async () => {
    this.props.onDelete(this.props.customField);
  };

  private isValid = () =>
    this.state.label.length > 0 && this.state.type.length > 0;

  private addOption = () => {
    const options = [ ...this.state.options, ''];
    this.setState({ options })
  }

  private onOptionChange = (title: string, index: number) => {
    const options = [ ...this.state.options];
    options.splice(index, 1, title);
    this.setState({ options });
  }

  private removeOption = (index: number) => {
    const options = [ ...this.state.options];
    options.splice(index, 1);
    this.setState({ options });
  }

  async save() {
    const customField = produce(this.props.customField, (field) => {
      field.label = this.state.label.trim();
      field.type = this.state.type.trim() as CustomFieldType;
      field.options = this.state.options;
      field.order = this.state.order;
    });
    this.props.onSave(customField);
  }

  public render() {
    const { t } = this.props;

    return (
      <Dialog
        className="bp3-dark"
        title={t('custom_fields.title')}
        canOutsideClickClose={false}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn flex={1}>
            <FormGroup inline label={t('custom_fields.label')}>
              <InputGroup
                fill
                value={this.state.label}
                onChange={(evt: any) => this.setState({ label: evt.currentTarget.value }) }
              />
            </FormGroup>
          </FlexColumn>

          <FlexColumn flex={1}>
            <FormGroup inline label={t('custom_fields.order')}>
              <NumericInput
                fill
                value={this.state.order}
                onValueChange={(numberValue, stringValue) => this.setState({ order: numberValue })}
              />
            </FormGroup>
          </FlexColumn>

          <FlexColumn flex={1}>
            <FormGroup inline label={t('custom_fields.type')}>
              <TypeSelect
                items={CustomFieldTypes}
                itemRenderer={this.typeRenderer}
                onItemSelect={this.onTypeSelect}
                activeItem={this.state.type}
                inputProps={{ placeholder: t('filter...') }}>
                <Button text={t(`custom_fields.${this.state.type}`)} rightIcon="chevron-down" />
              </TypeSelect>
            </FormGroup>

            { this.state.type === 'select' && <FormGroup inline label={t('custom_fields.options')}>
              <div className="custom-field-options-table">
                { this.state.options.map((option, idx) => <FlexRow className="f-1 m-b-12">
                  <InputGroup
                    fill
                    value={option}
                    onChange={((evt) => {
                      this.onOptionChange(evt.currentTarget.value, idx)
                    })}
                  />
                  <Button
                    minimal
                    intent="danger"
                    icon="trash"
                    onClick={() => this.removeOption(idx)}
                  />    
                </FlexRow>)}
                
              </div>
              <Button 
                outlined
                intent="primary"
                text={t('custom_fields.add_option')}
                icon="plus"
                onClick={this.addOption}
              />
              {/* <TypeSelect
                items={CustomFieldTypes}
                itemRenderer={this.typeRenderer}
                onItemSelect={this.onTypeSelect}
                activeItem={this.state.type}
                inputProps={{ placeholder: t('filter...') }}>
                <Button text={t(`custom_fields.${this.state.type}`)} rightIcon="chevron-down" />
              </TypeSelect> */}
            </FormGroup> }
          </FlexColumn>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <FlexRow>
            <div className="f-1">
              <ConfirmButton
                onConfirm={this.onDelete}
                confirmTitle={t('confirm')}
                title={t('patterns.delete')}
                minimal
              />
            </div>
            <div className="f-1 d-f f-r jc-e">
              <Button outlined text={t('close')} onClick={this.props.onClose} />
              <Button
                disabled={!this.isValid()}
                text={t('save')}
                intent="primary"
                onClick={() => this.save()}
                className="m-l-12"
              />
            </div>
          </FlexRow>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CustomFieldDialog);
