/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import { DataTable } from '@patterns/datatable';
import { FlexRow, Toolbar } from '@patterns/ui';
import { Button } from '@blueprintjs/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CustomField } from '../../models/custom_field.model';
import { IconRepository } from '../../repository';
import JustifiedGrid from 'react-justified-grid';
import { IconModel } from '../../models/icon.model';
import { convertFileToBase64 } from '@patterns/core';
import { axios } from '../../session';
import { arrayMove } from '../../common';

export type IconImage = {
  src: string;
  width: number;
  height: number;
  alt: string;
}

export interface Props extends WithTranslation {}

export interface State {
  icons: IconModel[];
  images: IconImage[];
  isUploading: boolean;
}

export class Icons extends React.Component<Props, State> {
  fileInput = React.createRef<HTMLInputElement>();

  table = React.createRef<DataTable<CustomField>>();

  constructor(props: Props) {
    super(props);
    this.state = {
      icons: [],
      images: [],
      isUploading: false
    };
  }

  componentDidMount() {
    this.fetch()
  }

  private fetch = async () => {
    const { items: icons } = await IconRepository.index(
      1,
      1000,
      'order',
      'asc',
      {}
    );

    const images = icons.map(icon => ({
      src: `https://print.jit.fi/images/icons/${icon.fileName}`,
      width: 100,
      height: 100,
      alt: icon.fileName
    }));

    this.setState({ icons, images })
  };

  private handleFileSelect = (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    this.setState({ isUploading: true }, async () => {
      const file = fileList[0];
      const data = await convertFileToBase64(file);
      await axios.post(`/icons`, {
        data,
        type: file.type,
      });
      this.setState({ isUploading: false }, () => this.fetch());
    });
  };

  private selectFile = () => {
    this.fileInput.current?.click();
  }

  private saveIconOrdering = async () => {
    const ordering = this.state.icons.map((i) => i.id);
  };

  private moveIconLeft = (index: number) => {
    const icons = [...this.state.icons];
    arrayMove(icons, index, index - 1);
    icons.forEach((i, idx) => {
      i.order = idx;
    });
    this.setState({ icons }, () => this.saveIconOrdering());
  };

  private moveIconRight = (index: number) => {
    const icons = [...this.state.icons];
    arrayMove(icons, index, index + 1);
    icons.forEach((i, idx) => {
      i.order = idx;
    });
    this.setState({ icons }, () => this.saveIconOrdering());
  };

  private trashIcon = (index: number) => {
    this.setState({ isUploading: true }, async () => {
      const icons = [...this.state.icons];
      const icon = icons[index];
      icons.splice(index, 1);
      icons.forEach((i, idx) => {
        i.order = idx;
      });
      await axios.delete(`/icons/${icon.id}`);
      this.setState({ icons, isUploading: false }, this.fetch);
    });
  };

  public render() {
    const { t } = this.props;

    return (
      <div className="p-l-24 p-r-24">
        <Toolbar className="p-l-24 m-b-24">
          <Button
            intent="primary"
            icon="plus"
            text={t('products.add_icon')}
            onClick={this.selectFile}
          />
        </Toolbar>

        <JustifiedGrid 
          images={this.state.images}
          maxRowHeight={100}
          gutter={1}
          showIncompleteRow={true}>
          {processedImages => {
            return processedImages.map((image, index) => {
              const { src, width, height } = image;
              return (
              <div className="image-box" key={`image-${index}`}>
                <div className="img-container">
                  <img
                    alt="icon"
                    src={src}
                    width={width}
                    height={height}
                  />
                </div>
                <FlexRow className="actions">
                  <Button
                    disabled={index === 0}
                    minimal
                    intent="primary"
                    icon="chevron-left"
                    onClick={() => this.moveIconLeft(index)}
                  />
                  <Button
                    minimal
                    icon="trash"
                    onClick={() => this.trashIcon(index)}
                  />
                  <Button
                    disabled={index === this.state.icons.length - 1}
                    minimal
                    icon="chevron-right"
                    onClick={() => this.moveIconRight(index)}
                  />
                </FlexRow>
              </div>
            )
          })
        }}
        </JustifiedGrid>

        <input
          accept="image/png, image/jpeg"
          type="file"
          ref={this.fileInput}
          onChange={(e) => this.handleFileSelect(e.target.files)}
          style={{ display: 'none' }}
        />

      </div>
    );
  }
}

export default withTranslation()(Icons);
