import * as React from 'react';
import { Button } from '@blueprintjs/core';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { CategoryRepository } from '../../repository';
import { Notifier, Toolbar } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Category } from '../../models/category.model';
import CategoryDialog from './category.dialog';

const CategoriesTable = DataTable.ofType<Category>();

export interface Props extends WithTranslation {
}

export interface State {
  selected: Category
  showDialog: boolean
}

export class CategoriesView extends React.Component<Props, State> {
  cancelToken: any;
  
  state = {
    selected: new Category({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: category => <span>{ category.name }</span>
      },
      {
        id: 'parent',
        title: t('parent'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: (category) => <span>{category.parent?.name ?? ''}</span>,
      },
      {
        id: 'createdAt',
        title: t('created_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true
      }
    ] as Column<Category>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await CategoryRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: Category) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onSave = async (category: Category) => {
    await CategoryRepository.save(category);
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
    Notifier.success(this.props.t('settings.category_save_success'))
  }

  private create = () => this.setState({
    selected: new Category({}),
    showDialog: true
  })

  private onClose = () => this.setState({ showDialog: false }, () => this.tableRef.current?.fetch())

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_category')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>

        <CategoriesTable 
          id="product-groups-table"
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="name"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <CategoryDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onSave={this.onSave}
          category={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(CategoriesView)