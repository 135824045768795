import { motion } from 'framer-motion';
import * as React from 'react';

const breadcrumbs = {
  hidden: { opacity: 0, scale: 1, x: -200 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

export interface Props {
  path?: string;
  title: string;
}

export class Breadcrumbs extends React.Component<Props> {
  public render() {
    return (
      <motion.div className="m-24 white" variants={breadcrumbs} initial="hidden" animate="visible">
        <h1 className="m-t-24">{ this.props.title.toUpperCase() }</h1>
      </motion.div>
    );
  }
}
