import { BaseModel, Selectable, Resource } from '@patterns/core'
import { deserializeString } from '@patterns/core'
import { RoleIdentifier } from './role.model';

@Resource('users')
export class User extends BaseModel implements Selectable {
  email: string;
  name: string;
  role: RoleIdentifier;
  password?: string;
  is_active: boolean;
  pin: string;
  // organization: Organization

  constructor(data: any = {}) {
    super(data)
    this.email = deserializeString(data.email);
    this.name = deserializeString(data.name);
    this.role = (data.role ? deserializeString(data.role) : 'user') as RoleIdentifier;
    this.password = deserializeString(data.password);
    this.pin = deserializeString(data.pin || '');
    this.is_active = data.is_active || true;
  }

  get isAdmin() {
    return this.role === 'admin'
  }

  get isSuperuser() {
    return this.role === 'superuser'
  }

  get isUser() {
    return this.role === 'user'
  }

  getLabel() { return this.email }
  getTitle() { return this.name }
}
