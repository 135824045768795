/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import {
  Alignment,
  Column,
  ColumnType,
  DataTable,
  FilterStates,
} from '@patterns/datatable';
import { Toolbar } from '@patterns/ui';
import { Button } from '@blueprintjs/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CustomField } from '../../models/custom_field.model';
import { CustomFieldRepository } from '../../repository';
// eslint-disable-next-line import/no-named-as-default
import CustomFieldDialog from './custom_field.dialog';

const CustomFieldTable = DataTable.ofType<CustomField>();

export interface Props extends WithTranslation {}

export interface State {
  selected: CustomField;
  showDialog: boolean;
}

export class CustomFields extends React.Component<Props, State> {
  table = React.createRef<DataTable<CustomField>>();

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: new CustomField({
        type: 'string',
        label: '',
        id: '',
      } as any),
      showDialog: false,
    };
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'id',
        title: t('custom_fields.id'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        visible: true,
        filterable: false,
        sortable: false,
        flex: 1,
      },
      {
        id: 'order',
        title: t('custom_fields.order'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        visible: true,
        filterable: false,
        sortable: false,
        flex: 1,
      },
      {
        id: 'label',
        title: t('custom_fields.label'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        visible: true,
        filterable: false,
        sortable: false,
        flex: 1,
      },
      {
        id: 'type',
        title: t('custom_fields.type'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        visible: true,
        filterable: false,
        sortable: false,
        flex: 1,
      },
    ] as Column<CustomField>[];
  };

  private fetch = async (
    page: number,
    pageSize: number,
    sort: string,
    dir: 'asc' | 'desc',
    filters: FilterStates
  ) => {
    const { items, total } = await CustomFieldRepository.index(
      page,
      pageSize,
      sort,
      dir,
      filters
    );
    return { items, total };
  };

  private onSelect = (selected: CustomField) =>
    this.setState({
      selected,
      showDialog: true,
    });

  private onDialogClose = () => this.setState({ showDialog: false });

  private onSave = async (field: CustomField) => {
    await CustomFieldRepository.save(field);
    this.setState({ showDialog: false }, () => this.table.current?.fetch());
  };

  private onDelete = async (field: CustomField) => {
    await CustomFieldRepository.delete(field.id);
    this.setState({ showDialog: false }, () => this.table.current?.fetch());
  };

  public render() {
    const { t } = this.props;

    return (
      <div className="p-l-24 p-r-24">
        <Toolbar className="p-l-24">
          <Button
            intent="primary"
            icon="plus"
            text={t('custom_fields.add_field')}
            onClick={() =>
              this.onSelect(
                new CustomField({
                  type: 'string',
                  label: '',
                  options: []
                } as any)
              )
            }
          />
        </Toolbar>

        <CustomFieldTable
          id="custom-fields-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          onItemSelect={this.onSelect}
        />

        <CustomFieldDialog
          isOpen={this.state.showDialog}
          customField={this.state.selected}
          onClose={this.onDialogClose}
          onSave={this.onSave}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

export default withTranslation()(CustomFields);
