import { BaseModel } from '@patterns/core';

/* eslint-disable @typescript-eslint/lines-between-class-members */
export interface CustomField {
  id: string;
  type: string;
  description: string;
}

export interface ITemplate {
  id: string;
  description: string;
  type: string;
  parameters: string;
  label: string;
  path: string;
}

export class Template extends BaseModel implements ITemplate {
  description: string;
  type: string;
  parameters: string;
  label: string;
  path: string;

  constructor(data: ITemplate) {
    super(data);
    this.description = data.description;
    this.type = data.type;
    this.parameters = data.parameters;
    this.label = data.label;
    this.path = data.path;
  }

  getLabel() {
    return this.description;
  }

  getTitle() {
    return this.id;
  }
}

export const DummyTemplate = new Template({
  id: 'dummy',
  description: 'Select Template',
  label: '',
  parameters: '',
  path: '',
  type: '',
});
