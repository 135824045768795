import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { FlexRow, Notifier } from '@patterns/ui';
import * as React from 'react';
import { axios } from '../session';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  isOpen: boolean
  onClose: () => void
}

interface State {
  confirmationIntent: Intent
  confirmationHelper: string
  currentPin: string
  newPin: string
  newPinConfirmation: string
  pinIntent: Intent
  pinHelper: string
  showPin: boolean
  isSaving: boolean
}

export class ChangePinDialog extends React.Component<Props, State> {
  state = {
    confirmationHelper: '',
    confirmationIntent: 'none',
    currentPin: '',
    newPin: '',
    newPinConfirmation: '',
    pinHelper: '',
    pinIntent: 'none',
    showPin: false,
    isSaving: false
  } as State;

  private close = () => this.props.onClose();

  private save = async () => {
    this.setState({ isSaving: true }, async () => {
      const { currentPin, newPin, newPinConfirmation } = this.state;
      const data = {
        currentPin,
        newPin,
        newPinConfirmation
      }
      try {
        await axios.post('/users/change_pin', data);
        Notifier.success(this.props.t('settings.pin_change_success'))
        this.close()
      } catch (e) {
        Notifier.failure(this.props.t('settings.pin_change_failure'));
      }
    })
  }

  private onNewPinChange = (evt: any) => {
    const value = evt.currentTarget.value;
    const valid = value.length > 3;
    const pinIntent = valid ? 'success' : 'warning';
    const pinHelper = valid ? '' : this.props.t('settings.pin_minlength');
    this.setState({ 
      newPin: evt.currentTarget.value, 
      pinIntent, 
      pinHelper 
    })
  }

  private onPinConfirmationChange = (evt: any) => {
    const value = evt.currentTarget.value;
    const valid = value === this.state.newPin;
    const confirmationIntent = valid ? 'success' : 'warning';
    const confirmationHelper = valid ? '' : this.props.t('settings.pin_confirmation_match');
    this.setState({ 
      newPinConfirmation: value, 
      confirmationIntent, 
      confirmationHelper 
    })
  }

  private onShowPin = () => this.setState({ showPin: !this.state.showPin })

  public render() {
    return (
      <Dialog isOpen={this.props.isOpen} onClose={this.props.onClose} title={this.props.t('settings.pin_change')} className='bp3-dark'>
        {/* { this.state.isSaving && <NonIdealState
          title="Please wait"
          description="Changing pin..."
          className="m-h-228"
        /> } */}

        <div className={`${Classes.DIALOG_BODY} p-12`}> 
          <FormGroup label={this.props.t('settings.pin_current')}>
            <InputGroup 
              type="password"
              value={this.state.currentPin}
              onChange={(evt: any) => this.setState({ currentPin: evt.currentTarget.value} )}
            />
          </FormGroup>

          <FormGroup label={this.props.t('settings.pin_new')} helperText={this.state.pinHelper}>
            <InputGroup 
              type="password"
              intent={this.state.pinIntent}
              value={this.state.newPin}
              onChange={this.onNewPinChange}
            />
          </FormGroup>

          <FormGroup label={this.props.t('settings.pin_confirmation')} helperText={this.state.confirmationHelper}>
            <InputGroup 
              type="password"
              intent={this.state.confirmationIntent}
              value={this.state.newPinConfirmation}
              onChange={this.onPinConfirmationChange}
              // rightElement={<Tooltip2
              //   content={`${this.state.showPin ? "Hide" : "Show"} Pin`}>
              //   <Button
              //     icon={this.state.showPin ? "unlock" : "lock"}
              //     intent={Intent.WARNING}
              //     minimal={true}
              //     onClick={this.onShowPin}
              //   />
              //   </Tooltip2>
              // }
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow>
              <FlexRow flex={1}>
                <Button
                  icon="cross"
                  text={this.props.t('patterns.cancel')}
                  onClick={this.close}
                  loading={this.state.isSaving}
                />    
              </FlexRow>
              <FlexRow className='jc-e'>
                <Button 
                  disabled={!(this.state.currentPin.length > 3 && this.state.newPin.length > 3 && this.state.newPin === this.state.newPinConfirmation)}
                  icon="tick"
                  text={this.props.t('settings.change_pin')}
                  intent="success"
                  onClick={this.save}
                  className="m-r-12"
                  loading={this.state.isSaving}
                />
              </FlexRow>
            </FlexRow>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ChangePinDialog);
