/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { Navbar, Alignment, Button, Icon } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import 'moment/locale/sk';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import logoJson from '../lotties/8170-file-printer.json';
import { session } from '../session';
import { LottieIcon } from './lottie_icon';
import ChangePasswordDialog from './change_password.dialog';

interface Props extends WithTranslation, RouteComponentProps {}

export function NavBar({ history, i18n }: Props) {
  const [ showPasswordChange, setShowPasswordChange ] = useState(false);

  const changePassword = useCallback(() => {
    setShowPasswordChange(true);
  }, []);
  
  const onPasswordDialogClose = useCallback(() => {
    setShowPasswordChange(false);
  }, []);

  if (!session.loggedIn) {
    return null;
  }

  const { t } = i18n;

  return (
    <Navbar className="navbar" style={{ display: 'flex' }}>
      <Navbar.Group align={Alignment.LEFT} style={{ flex: 1 }}>
        <Navbar.Heading style={{ width: 260 }}>
          <div className="navbar-logo d-f f-r">
            {/* <img alt="logo" src={logoBlack}/> */}
            <LottieIcon animation={logoJson} width={42} height={42} size={48} />
            <div style={{ marginLeft: 12, fontSize: 20, paddingTop: 4 }}>
              <strong>JIT</strong> Print
            </div>
          </div>
        </Navbar.Heading>
{/* 
        <Button
          minimal
          intent="primary"
          text={t('customers.title')}
          icon={
            <Icon
              icon="people"
              style={{
                backgroundColor: '#167DFA',
                padding: 6,
                borderRadius: 4,
                marginRight: 12,
              }}
              color="white"
            />
          }
          onClick={() => history.push('/customers')}
          className="uppercase"
        /> */}

        <Button
          minimal
          intent="primary"
          text={t('products.title')}
          icon={
            <Icon
              icon="list"
              style={{
                backgroundColor: '#3BCA5B',
                padding: 6,
                borderRadius: 4,
                marginRight: 12,
              }}
              color="white"
            />
          }
          onClick={() => history.push('/products')}
          className="m-l-12 uppercase"
        />

        <Button
          minimal
          intent="primary"
          text={t('settings.title')}
          icon={
            <Icon
              icon="list"
              style={{
                backgroundColor: '#F83059',
                padding: 6,
                borderRadius: 4,
                marginRight: 12,
              }}
              color="white"
            />
          }
          onClick={() => history.push('/settings')}
          className="uppercase"
        />
      </Navbar.Group>

      <Navbar.Group
        align={Alignment.RIGHT}
        style={{ flex: 1, justifyContent: 'flex-end' }}
      >
        <FlexRow className="jc-e ai-c">
          <div className="f-1" />

          <Button
            minimal
            disabled={i18n.language === 'en'}
            text="en"
            onClick={() => i18n.changeLanguage('en')}
          />
          <Button
            minimal
            disabled={i18n.language === 'fi'}
            text="fi"
            onClick={() => i18n.changeLanguage('fi')}
          />

          <FlexRow className="m-l-24 ai-c jc-e f-1">

            <span className="bold">{session.user.name}</span>

            <Button
              minimal
              icon="lock"
              onClick={changePassword}
              className='m-l-24'
            />
            <div
              className="d-f ai-c jc-c pointer"
              onClick={() => {
                window.location.pathname = '/logout';
              }}
            >
              <Icon
                color="#E8567D"
                icon="power"
                iconSize={24}
                className="m-l-12"
              />
            </div>
          </FlexRow>
        </FlexRow>
      </Navbar.Group>

      <ChangePasswordDialog 
        isOpen={showPasswordChange}
        onClose={onPasswordDialogClose}
      />
    </Navbar>
  );
}

export default withTranslation()(withRouter(NavBar));
