import React from 'react'
import { Button, FormGroup, InputGroup, Callout, Colors } from '@blueprintjs/core'
import { hasStrings } from '@patterns/core'
import { User } from '../models/user.model'

import logoJson from '../lotties/8170-file-printer.json'
import sample from '../assets/bg.mov';

import { motion } from 'framer-motion'
import { WithTranslation, withTranslation } from 'react-i18next';
import { FlexColumn, FlexRow } from '@patterns/ui';

import { session } from '../session';
import { LottieIcon } from '../components/lottie_icon';


const dimmer = {
  hidden: { backgroundColor: 'rgba(0, 0, 0, 0)' },
  visible: {
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.5
    }
  }
};

const container = {
  hidden: { 
    opacity: 0, scale: 0, transform: 'rotate(-180deg)'
  },
  visible: {
    opacity: 1,
    scale: 1,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    boxShadow: '0 5px 48px rgba(0, 0, 0, 0.5)',
    transition: {
      delay: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const logoVariants = {
  hidden: { 
    opacity: 0,
    scale: 3
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.5
    }
  }
}

const errorVariants = {
  hidden: { 
    opacity: 0,
    scale: 0
  },
  visible: {
    opacity: 1,
    scale: 1
  }
}

interface LoginProps extends WithTranslation {
  onLogin: (user: User) => void
}

interface LoginState {
  userName: string,
  password: string,
  error: boolean
}

export class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      userName: '',
      password: '',
      error: false
    }
  }

  async login() {
    const userName = this.state.userName;
    const password = this.state.password;
    if (hasStrings([userName, password])) {
      try {
        const { user } = await session.login(userName, password);
        this.props.onLogin(user)
      } catch (e) {
        console.error('e', e);
        this.setState({ error: true })
      }
    }
  }

  update(event: any, field: string) {
    const state : any = {};
    state[field] = event.target.value;
    this.setState(state)
  }

  renderError() {
    if (!this.state.error) {
      return null
    }

    return (<div className="row login-error">
      { this.props.t('invalid_password') }
    </div>)
  }

  render() {
    const { t } = this.props;

    return (
      <div className="App">
        <div className="background"/>
        <div className="background-video">
          <video className='videoTag' autoPlay loop muted>
            <source src={sample} type='video/mp4' />
          </video>
        </div>
        <motion.div 
          className="foreground"
          variants={dimmer}
          initial="hidden"
          animate="visible">
          <motion.div
            className="login-window"
            variants={container}
            initial="hidden"
            animate="visible"
            onMouseEnter={() => {
              document.getElementsByClassName('background-video')[0].className = "background-video blurred"
              document.getElementsByClassName('foreground')[0].className = "foreground blurred"
            }}
            onMouseLeave={() => {
              document.getElementsByClassName('background-video')[0].className = "background-video"
              document.getElementsByClassName('foreground')[0].className = "foreground"
            }}
          >
          <FlexColumn>
            <FlexRow>
              <motion.div
                className="logo"
                variants={logoVariants}
                initial="hidden"
                animate="visible">
                <FlexRow>
                  <LottieIcon 
                    animation={logoJson}
                    width={84}
                    height={84}
                    size={84}
                  />
                  <div style={{ marginLeft: 12, fontSize: 32, paddingTop: 22, color: 'white' }}><strong>JIT</strong> Print</div>
                </FlexRow>
              </motion.div>
            </FlexRow>
            <FlexRow>
              <FormGroup label={t('email')} inline>
                <InputGroup
                  placeholder={t('login.email_placeholder')}
                  className="text-input-plain"
                  value={this.state.userName}
                  onChange={(event: any) => this.update(event, 'userName')}
                />
              </FormGroup>
            </FlexRow>
            <FlexRow>
              <FormGroup label={t('password')} inline>
                <InputGroup 
                  placeholder={t('login.password_placeholder')}
                  className="text-input-plain"
                  value={this.state.password}
                  onChange={(event: any) => this.update(event, 'password')}
                  type="password"
                />
              </FormGroup>
            </FlexRow>
            <FlexRow>
              { this.state.error && <motion.div 
                style={{ flex: 1 }}
                variants={errorVariants}
                initial="hidden"
                animate="visible">
                <Callout title={t('login.invalid_password')} intent="danger" style={{ fontWeight: 'normal', fontSize: 14 }}/>
              </motion.div> }
            </FlexRow>
            <FlexRow style={{ justifyContent: 'flex-end', marginTop: 22 }}>
              <Button style={{ backgroundColor: Colors.VERMILION3 }} text={t('login.log_in')} className="login-btn" onClick={() => { 
                this.setState({ error: false });
                this.login()
              }}/>
            </FlexRow>
          </FlexColumn>
          </motion.div>
        </motion.div>
      </div>
    )
  }
}

export default withTranslation()(Login)