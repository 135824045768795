import Axios from 'axios'
import { axios } from './session'
import { BaseModel, Constructable } from '@patterns/core';
import { FilterStates, FilterState } from '@patterns/datatable';

export interface ApiResource {
  new(data: any): BaseModel
}

interface MyConstructable extends Constructable {
  _url?: string | undefined;
}

export class ApiRepository<T extends BaseModel> {
  public resource: string
  public url: string
  public klass: Constructable

  constructor(klass: MyConstructable) {
    this.resource = klass.resource || ''
    this.url = klass._url || this.resource;
    this.klass = klass;
    (window as any).koko = this;
  }

  async index(
    page: number = 0, 
    pageSize: number = 50, 
    sort: string = 'createdAt', 
    sortDir: string = 'asc', 
    filters: FilterStates = {}, 
    _cancelToken: any = null,
    extraParams?: string) {
      if (_cancelToken) { _cancelToken() }
      let cancelToken;
  
      const _filters = [] as FilterState[];
      Object.keys(filters).forEach(key => {
        _filters.push(filters[key])
      })

      let params = [
        `page=${page}`,
        `pageSize=${pageSize}`,
        `sort=${sort}`,
        `sortDir=${sortDir.toUpperCase()}`,
      ].join('&')
      
      if (extraParams) {
        params += `&${extraParams}`
      }

      if (_filters.length > 0) {
        params = `${params}&filters=${JSON.stringify(_filters)}`
      }
  
      const response = await axios.get(`/${this.url}?${params}`, {
        cancelToken: new (Axios as any).CancelToken((token: any) => {
          cancelToken = token
        })
      })

      const _data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      const total = _data.count;
      // console.log('response', typeof response.data, _data);
      const items = _data[this.resource].map((item: any) => new this.klass(item)) as T[];
      return { items, total, cancelToken }
  }

  async search(query: string, params: string, cancelToken: any) {
    const _params = [params, query].filter(f => f).join('&')
    const response = await axios.get(`/${this.url}/search?${_params}`, {
      cancelToken: new (Axios as any).CancelToken((token: any) => {
        cancelToken = token
      })
    })

    const total = response.data.count;
    const items = response.data[this.resource].map((item: any) => new this.klass(item)) as T[];
    return { items, total, cancelToken }
  }

  async get(id: string) {
    const response = await axios.get(`/${this.resource}/${id}`);
    return new this.klass(response.data) as T
  }

  async save(item: T) {
    let response;
    if (item.exists) {
      response = await axios.put(`/${this.resource}/${item.id}`, item)
    } else {
      response = await axios.post(`/${this.resource}`, item)
    }

    return new this.klass(response.data)
  }

  async delete(id: string) {
    const response = await axios.delete(`/${this.resource}/${id}`)
    return new this.klass(response.data)
  }
}
