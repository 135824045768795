/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-did-update-set-state */
import * as React from 'react';
import {
  Dialog,
  Classes,
  FormGroup,
  InputGroup,
  Button,
  Checkbox,
} from '@blueprintjs/core';
import { FlexColumn } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RoleSelect } from '../role_select';
import { RoleIdentifier } from '../../models/role.model';
import { User } from '../../models/user.model';

export interface Props extends WithTranslation {
  isOpen: boolean;
  onClose: () => void;
  onSave: (user: User) => void;
  user: User;
}

export interface State {
  name: string;
  password: string;
  email: string;
  role: RoleIdentifier;
  is_active: boolean;
}

export class UserDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { name, email, role, is_active } = this.props.user;
    this.state = { name, email, role, is_active, password: '' };
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.user, prevProps.user)) {
      const { name, email, role, is_active } = this.props.user;
      this.setState({ name, email, role, is_active });
    }
  }

  async save() {
    const user = produce(this.props.user, (_user) => {
      _user.name = this.state.name.trim();
      _user.email = this.state.email.trim();
      _user.role = this.state.role;
      _user.is_active = this.state.is_active;
      _user.password = this.state.password;
    });
    this.props.onSave(user);
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog
        className="bp3-dark"
        title={t('users.title')}
        canOutsideClickClose={false}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn flex={1}>
            <FormGroup inline label={t('users.name')}>
              <InputGroup
                fill
                value={this.state.name}
                onChange={(evt: any) =>
                  this.setState({ name: evt.currentTarget.value })
                }
              />
            </FormGroup>

            <FormGroup inline label={t('users.email')}>
              <InputGroup
                fill
                value={this.state.email}
                onChange={(evt: any) =>
                  this.setState({ email: evt.currentTarget.value })
                }
              />
            </FormGroup>

            <FormGroup inline label={t('users.password')}>
              <InputGroup
                fill
                value={this.state.password}
                security="true"
                onChange={(evt: any) =>
                  this.setState({ password: evt.currentTarget.value })
                }
              />
            </FormGroup>

            <RoleSelect
              inline
              label={t('users.role')}
              role={this.state.role}
              onChange={(role) => this.setState({ role: role.code })}
            />

            <FormGroup inline label={t('users.is_active')}>
              <Checkbox
                checked={this.state.is_active}
                onChange={(evt: any) =>
                  this.setState({ is_active: evt.currentTarget.checked })
                }
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button outlined text={t('close')} onClick={this.props.onClose} />
            <Button
              outlined
              text={t('save')}
              intent="primary"
              onClick={() => this.save()}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(UserDialog);
