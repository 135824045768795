import * as React from 'react';
import {
  Dialog,
  Classes,
  FormGroup,
  InputGroup,
  Button,
} from '@blueprintjs/core';
import { ConfirmButton, FlexColumn, FlexRow } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Category } from '../../models/category.model';
import CategorySelect from '../category.select';
import { CategoryRepository } from '../../repository';

export interface Props extends WithTranslation {
  isOpen: boolean;
  onClose: () => void;
  onSave: (category: Category) => void;
  category: Category;
}

export interface State {
  name: string;
  parent: Category;
}

export class CategoryDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { name, parent } = this.props.category;
    this.state = { name: name || '', parent: parent || new Category() };
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.category, prevProps.category)) {
      const { name, parent } = this.props.category;
      this.setState({ name: name || '', parent: parent || new Category() });
    }
  }

  private onDelete = async () => {
    await CategoryRepository.delete(this.props.category.id);
    this.props.onClose();
  };

  async save() {
    const category = produce(this.props.category, (category) => {
      category.name = this.state.name.trim();
      if (this.state.parent.exists) {
        category.parent = this.state.parent;
      }
    });
    this.props.onSave(category);
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog
        className="bp3-dark"
        title={t('settings.category')}
        canOutsideClickClose={false}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn flex={1}>
            <FormGroup inline label={t('name')}>
              <InputGroup
                fill
                value={this.state.name}
                onChange={(evt: any) =>
                  this.setState({ name: evt.currentTarget.value })
                }
              />
            </FormGroup>
            <FormGroup inline label={t('parent')}>
              <CategorySelect
                ignoreParent={true}
                category={this.state.parent}
                onChange={(parent) => this.setState({ parent })}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <FlexRow>
            <div className="f-1">
              <ConfirmButton
                onConfirm={this.onDelete}
                confirmTitle={t('confirm')}
                title={t('delete')}
                minimal
              />
            </div>
            <div className="f-1 d-f f-r jc-e">
              <Button outlined text={t('close')} onClick={this.props.onClose} />
              <Button
                text={t('save')}
                intent="primary"
                onClick={() => this.save()}
                className="m-l-12"
              />
            </div>
          </FlexRow>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CategoryDialog);
