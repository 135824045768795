import * as React from 'react';
import Lottie from 'react-lottie-player';

export interface Props {
  animation: object
  className?: string
  width: number
  height: number
  size: number
  reverse?: boolean
  firstFrame?: number
  onClick?: () => void
}

export interface State {
  hover: boolean
  goTo: number
}

export class LottieIcon extends React.Component<Props, State> {
  state = {
    hover: false,
    goTo: 0
  } as State;

  private onClick = () => this.setState({ hover: false, goTo: 0 }, () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  });

  private onMouseEnter = () => this.setState({ hover: true, goTo: 0 });
  private onMouseLeave = () => this.setState({ hover: false, goTo: 0 });
  private onEnterFrame = (frame: number) => this.setState({ goTo: frame });
  
  public render() {
    const { width, height, size, className: _className } = this.props;
    const className = ['lottie-icon', _className].filter(f => f).join(' ');
    const isReverse = this.props.reverse === true;

    return <div 
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}
      className={className}
      onClick={this.onClick}
      style={{ width: size, height: size }}>

      <Lottie
        animationData={this.props.animation}
        play
        direction={isReverse ? -1 : 1}
        goTo={0}
        loop
        style={{ width, height, backgroundColor: 'transparent', resize: 'both' }}
        onEnterFrame={this.onEnterFrame}
      />

    </div>
  }
}