import React from 'react';
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@patterns/datatable/build/data-table.css';
import '@patterns/ui/build/main.css';
import '@patterns/ui/build/mini.css';

import './App.scss';

import { Session, session } from './session';
import { User } from './models/user.model';
import styled from 'styled-components';
import { Colors } from '@blueprintjs/core';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import Login from './pages/login';
import Main from './pages/main';
import { Locale, LocaleManager } from '@patterns/locale';
import fi from './i18n/fi.json'

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: ${Colors.LIGHT_GRAY5};
  overflow: hidden;
`;

LocaleManager.instance.setLocale('fi', (fi as unknown as any).patterns as Locale);
(window as any).localeManager= LocaleManager.instance;

export interface Props {}

export interface State {
  loggedIn: boolean
  session: Session<User>
}

export default class App extends React.Component<Props, State> {
  state = {
    loggedIn: session.loggedIn,
    session
  } as State

  componentDidMount() {
    (window as any).session = session;
    this.restore()
  }

  componentWillUnmount() {
    session.cancelAll(this.onSessionChange)
  }

  private onSessionChange = () => {
    this.setState({ session, loggedIn: session.loggedIn })
  }

  private restore = async () => {
    session.observeAll(this.onSessionChange);
    await session.restore();
    this.setState({ loggedIn: session.loggedIn });
  }

  private onLogin = () => this.setState({ loggedIn: true })
  private onLogout = () => this.setState({ loggedIn: false })

  public render() {
    return (
      <div className="app-container">
        <AppContainer>
          <I18nextProvider i18n={i18n}>
            {/* <AppContextProvider> */}
              { !this.state.loggedIn && <Login onLogin={this.onLogin} /> }
              { this.state.loggedIn && <Main onLogout={this.onLogout}/> }
            {/* </AppContextProvider> */}
            {/* <LoadingBar /> */}
          </I18nextProvider>
        </AppContainer>
      </div>
    );
  }
}