/* eslint-disable import/no-named-as-default */
import * as React from 'react';
import { Button, Checkbox, Tag } from '@blueprintjs/core';
import {
  DataTable,
  Column,
  ColumnType,
  Alignment,
  FilterStates,
} from '@patterns/datatable';
import { Notifier, Toolbar } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';
import { User } from '../../models/user.model';
import UserDialog from './user.dialog';
import ChangePinDialog from '../change_pin.dialog';
import { UserRepository } from '../../repository';

const UsersTable = DataTable.ofType<User>();

export type Props = WithTranslation;

export interface State {
  selected: User;
  showDialog: boolean;
  showPinDialog: boolean;
}

export class UsersView extends React.Component<Props, State> {
  cancelToken: any;

  tableRef = React.createRef<any>();

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: new User({}),
      showDialog: false,
      showPinDialog: false
    };
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('users.name'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: (user) => <span>{user.name}</span>,
      },
      {
        id: 'email',
        title: t('users.email'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'role',
        title: t('users.role'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: ({ role }) => {
          let intent: 'none' | 'danger' | 'success' = 'none';
          if (role === 'superuser') intent = 'success';
          if (role === 'admin') intent = 'danger';
          return <Tag intent={intent}>{role}</Tag>;
        },
      },
      {
        id: 'is_active',
        title: t('users.is_active'),
        type: ColumnType.Custom,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 110,
        visible: true,
        format: (user) => <Checkbox checked={user.is_active} />,
      },
      {
        id: 'createdAt',
        title: t('users.created_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true,
      },
      {
        id: 'updatedAt',
        title: t('users.updated_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true,
      },
    ] as Column<User>[];
  };

  private fetch = async (
    page: number,
    pageSize: number,
    sort: string,
    sortDir: string,
    filters: FilterStates
  ) => {
    const { items, total, cancelToken } = await UserRepository.index(
      page,
      pageSize,
      sort,
      sortDir,
      filters,
      this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total };
  };

  private onSelect = (selected: User) =>
    this.setState({
      selected,
      showDialog: true,
    });

  private onSave = async (user: User) => {
    if (!user.password || user.password.length === 0) {
      delete user.password;
    }
    // user.organization = sessi/on.user.organization;

    await UserRepository.save(user);
    this.setState({
      showDialog: false,
    });
    this.tableRef.current?.fetch();
    Notifier.success(this.props.t('settings.user_save_success'));
  };

  private create = () =>
    this.setState({
      selected: new User({}),
      showDialog: true,
    });

  private onClose = () => this.setState({ showDialog: false });

  private changePIN = () => this.setState({ showPinDialog: true });
  private onPinClose = () => this.setState({ showPinDialog: false });

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button
              intent="primary"
              text={this.props.t('settings.add_user')}
              icon="plus"
              onClick={this.create}
            />
            <Button
              intent="primary"
              text={this.props.t('settings.pin_change')}
              icon="lock"
              onClick={this.changePIN}
              className='m-l-12'
            />
          </div>
        </Toolbar>
        <UsersTable
          id="users-table"
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="name"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <UserDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onSave={this.onSave}
          user={this.state.selected}
        />

        <ChangePinDialog 
          isOpen={this.state.showPinDialog}
          onClose={this.onPinClose}
        />
      </div>
    );
  }
}

export default withTranslation()(UsersView);
