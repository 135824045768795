import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { FlexRow, Notifier } from '@patterns/ui';
import * as React from 'react';
import { axios } from '../session';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  isOpen: boolean
  onClose: () => void
}

interface State {
  confirmationIntent: Intent
  confirmationHelper: string
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
  passwordIntent: Intent
  passwordHelper: string
  showPassword: boolean
  isSaving: boolean
}

export class ChangePasswordDialog extends React.Component<Props, State> {
  state = {
    confirmationHelper: '',
    confirmationIntent: 'none',
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    passwordHelper: '',
    passwordIntent: 'none',
    showPassword: false,
    isSaving: false
  } as State;

  private close = () => this.props.onClose();

  private save = async () => {
    this.setState({ isSaving: true }, async () => {
      const { currentPassword, newPassword, newPasswordConfirmation } = this.state;
      const data = {
        currentPassword,
        newPassword,
        newPasswordConfirmation
      }
      try {
        await axios.post('/users/change_password', data);
        Notifier.success(this.props.t('settings.password_change_success'))
        this.close()
      } catch (e) {
        Notifier.failure(this.props.t('settings.password_change_failure'));
      }
    })
  }

  private onNewPasswordChange = (evt: any) => {
    const value = evt.currentTarget.value;
    const valid = value.length > 7;
    const passwordIntent = valid ? 'success' : 'warning';
    const passwordHelper = valid ? '' : this.props.t('settings.password_minlength');
    this.setState({ 
      newPassword: evt.currentTarget.value, 
      passwordIntent, 
      passwordHelper 
    })
  }

  private onPasswordConfirmationChange = (evt: any) => {
    const value = evt.currentTarget.value;
    const valid = value === this.state.newPassword;
    const confirmationIntent = valid ? 'success' : 'warning';
    const confirmationHelper = valid ? '' : this.props.t('settings.password_confirmation_match');
    this.setState({ 
      newPasswordConfirmation: value, 
      confirmationIntent, 
      confirmationHelper 
    })
  }

  private onShowPassword = () => this.setState({ showPassword: !this.state.showPassword })

  public render() {
    return (
      <Dialog isOpen={this.props.isOpen} onClose={this.props.onClose} title={this.props.t('settings.password_change')} className='bp3-dark'>
        {/* { this.state.isSaving && <NonIdealState
          title="Please wait"
          description="Changing password..."
          className="m-h-228"
        /> } */}

        <div className={`${Classes.DIALOG_BODY} p-12`}> 
          <FormGroup label={this.props.t('settings.password_current')}>
            <InputGroup 
              type="password"
              value={this.state.currentPassword}
              onChange={(evt: any) => this.setState({ currentPassword: evt.currentTarget.value} )}
            />
          </FormGroup>

          <FormGroup label={this.props.t('settings.password_new')} helperText={this.state.passwordHelper}>
            <InputGroup 
              type="password"
              intent={this.state.passwordIntent}
              value={this.state.newPassword}
              onChange={this.onNewPasswordChange}
            />
          </FormGroup>

          <FormGroup label={this.props.t('settings.password_confirmation')} helperText={this.state.confirmationHelper}>
            <InputGroup 
              type="password"
              intent={this.state.confirmationIntent}
              value={this.state.newPasswordConfirmation}
              onChange={this.onPasswordConfirmationChange}
              // rightElement={<Tooltip2
              //   content={`${this.state.showPassword ? "Hide" : "Show"} Password`}>
              //   <Button
              //     icon={this.state.showPassword ? "unlock" : "lock"}
              //     intent={Intent.WARNING}
              //     minimal={true}
              //     onClick={this.onShowPassword}
              //   />
              //   </Tooltip2>
              // }
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow>
              <FlexRow flex={1}>
                <Button
                  icon="cross"
                  text={this.props.t('patterns.cancel')}
                  onClick={this.close}
                  loading={this.state.isSaving}
                />    
              </FlexRow>
              <FlexRow className='jc-e'>
                <Button 
                  disabled={!(this.state.currentPassword.length > 7 && this.state.newPassword.length > 7 && this.state.newPassword === this.state.newPasswordConfirmation)}
                  icon="tick"
                  text={this.props.t('settings.change_password')}
                  intent="success"
                  onClick={this.save}
                  className="m-r-12"
                  loading={this.state.isSaving}
                />
              </FlexRow>
            </FlexRow>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ChangePasswordDialog);
