import { BaseModel, Selectable, Resource } from '@patterns/core'

@Resource('categories')
export class Category extends BaseModel implements Selectable {
  name: string;

  parent?: Category;

  constructor(data: any = {}) {
    super(data)
    this.name = data.name;
    if (data.parent) {
      this.parent = new Category(data.parent)
    }
  }

  get hasParent(): boolean {
    return (typeof this.parent !== 'undefined' && this.parent.exists) || typeof this.parent === 'undefined'
  }

  getLabel() { return this.name }
  getTitle() { return this.name }
}
