import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../layouts/main.layout';
import { FlexColumn } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumbs } from '../breadcrumbs';

interface Props extends WithTranslation, RouteComponentProps {}

export class Customers extends React.Component<Props> {
  public render() {
    const { t } = this.props;
    return (
      <MainLayout
        title={this.props.t('customers')}>
        <Breadcrumbs title={t('customers')}/>
        <FlexColumn flex={1}>
          customers
        </FlexColumn>
      </MainLayout>
    );
  }
}

export default withTranslation()(Customers)
