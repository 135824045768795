import { BaseModel, Selectable, Resource } from '@patterns/core'

@Resource('product_groups')
export class ProductGroup extends BaseModel implements Selectable {
  name: string;
  
  constructor(data: any = {}) {
    super(data)
    this.name = data.name;
  }

  getLabel() { return this.name }
  getTitle() { return this.name }
}
