import { ApiRepository } from './api_repository';
import { Category } from './models/category.model';
import { CustomField } from './models/custom_field.model';
import { IconModel } from './models/icon.model';
import { MasterProduct } from './models/master_product.model';
import { Product } from './models/product.model';
import { ProductGroup } from './models/product_group.model';
import { Template } from './models/template.model';
import { User } from './models/user.model';

export const UserRepository = new ApiRepository<User>(User);
export const CategoryRepository = new ApiRepository<Category>(Category);
export const ProductRepository = new ApiRepository<Product>(Product);
export const MasterProductRepository = new ApiRepository<MasterProduct>(MasterProduct);
export const ProductGroupRepository = new ApiRepository<ProductGroup>(ProductGroup);
export const TemplateRepository = new ApiRepository<Template>(Template);
export const CustomFieldRepository = new ApiRepository<CustomField>(CustomField);
export const IconRepository = new ApiRepository<IconModel>(IconModel);
